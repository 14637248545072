import React, { createContext, useContext, useState, useEffect } from 'react';
import usePremiumModal from '../hooks/usePremiumModal';

interface PremiumContextType {
  isPremium: boolean;
  checkPremiumStatus: () => Promise<void>;
  requirePremium: () => Promise<boolean>;
}

const PremiumContext = createContext<PremiumContextType | undefined>(undefined);

export const PremiumProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { open, close } = usePremiumModal();

  const checkPremiumStatus = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscription`, {
        credentials: 'include',
      });
      
      if (!response.ok) {
        setIsPremium(false);
        return;
      }

      const data = await response.json();
      console.log('Subscription data:', data);
      
      console.log('Setting isPremium to:', data.has_subscription && data.status === 'active');
      setIsPremium(data.has_subscription && data.status === 'active');
      
    } catch (error) {
      console.error('Error checking premium status:', error);
      setIsPremium(false);
    } finally {
      setIsLoading(false);
    }
  };

  const requirePremium = async () => {
    // If already premium, don't do anything
    if (isPremium) return true;
    
    // Check status
    await checkPremiumStatus();
    
    // After the check, if we're premium, return true
    if (isPremium) return true;
    
    // Only if we're definitely not premium after checking, show modal
    if (!isLoading && !isPremium) {
      open();
      return false;
    }
    
    return false;
  };

  // Add this useEffect to close modal when premium status changes
  useEffect(() => {
    if (isPremium) {
      close();  // Close the modal if user becomes premium
    }
  }, [isPremium]);

  useEffect(() => {
    checkPremiumStatus();
  }, []);

  return (
    <PremiumContext.Provider value={{ isPremium, checkPremiumStatus, requirePremium }}>
      {children}
    </PremiumContext.Provider>
  );
};

export const usePremium = () => {
  const context = useContext(PremiumContext);
  if (context === undefined) {
    throw new Error('usePremium must be used within a PremiumProvider');
  }
  return context;
}; 