import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import { HandThumbUpIcon, HandThumbDownIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { HandThumbUpIcon as HandThumbUpSolidIcon, HandThumbDownIcon as HandThumbDownSolidIcon, ClipboardIcon as ClipboardSolidIcon } from '@heroicons/react/24/solid';
import { Toast } from './ui/Toast';

interface MessageProps {
  sender: string;
  content: string;
  avatar?: string;
  name?: string;
  isLast?: boolean;
  messageId?: string;
}

function Message({ sender, avatar, name, content, isLast, messageId }: MessageProps) {
  const [rating, setRating] = useState<'up' | 'down' | null>(null);
  const [copied, setCopied] = useState(false);
  const [showToast, setShowToast] = useState(false);
  
  const isUser = sender === 'user';
  const displayName = isUser && !name ? 'User' : name;

  const handleFeedback = async (feedback: 'up' | 'down') => {
    if (rating || !messageId) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages/${messageId}/rating`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ rating: feedback }),
      });
      
      if (response.ok) {
        setRating(feedback);
      }
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setCopied(true);
      setShowToast(true);
      setTimeout(() => {
        setCopied(false);
        setShowToast(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy content:', err);
    }
  };

  return (
    <div className={`message ${isUser ? 'user-message' : 'bot-message'}`}>
      <div className="avatar">
        {isUser ? (
          <div className="user-initial">{name ? name.charAt(0) : 'U'}</div>
        ) : (
          <img src={avatar} alt={`${displayName}'s avatar`} />
        )}
      </div>
      <div className="message-body">
        <div className="name">{displayName}</div>
        <div className="content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeSanitize]}
          >
            {content}
          </ReactMarkdown>
        </div>
        {!isUser && (
          <div className="message-actions flex gap-2 mt-2">
            <button
              onClick={() => handleFeedback('up')}
              className={`action-button ${rating === 'up' ? 'text-green-500' : 'text-gray-400'} hover:text-green-600`}
              aria-label="Thumbs up"
              disabled={rating !== null}
            >
              {rating === 'up' ? (
                <HandThumbUpSolidIcon className="h-5 w-5" />
              ) : (
                <HandThumbUpIcon className="h-5 w-5" />
              )}
            </button>
            <button
              onClick={() => handleFeedback('down')}
              className={`action-button ${rating === 'down' ? 'text-red-500' : 'text-gray-400'} hover:text-red-600`}
              aria-label="Thumbs down"
              disabled={rating !== null}
            >
              {rating === 'down' ? (
                <HandThumbDownSolidIcon className="h-5 w-5" />
              ) : (
                <HandThumbDownIcon className="h-5 w-5" />
              )}
            </button>
            <button
              onClick={handleCopy}
              className={`action-button ${copied ? 'text-blue-500' : 'text-gray-400'} hover:text-blue-600`}
              aria-label="Copy message"
            >
              {copied ? (
                <ClipboardSolidIcon className="h-5 w-5" />
              ) : (
                <ClipboardIcon className="h-5 w-5" />
              )}
            </button>
          </div>
        )}
      </div>
      {showToast && (
        <Toast 
          message="Message copied to clipboard"
          type="success"
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
}

export default Message;