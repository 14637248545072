import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Add password validation
        if (newPassword.length < 8) {
            setError('New password must be at least 8 characters long');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    currentPassword,
                    newPassword
                }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess('Password successfully changed');
                // Clear form
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                // Handle specific error cases
                switch (response.status) {
                    case 401:
                        setError('Current password is incorrect');
                        break;
                    case 404:
                        setError('User not found or no password set');
                        break;
                    default:
                        setError(data.error || 'Failed to change password');
                }
            }
        } catch (error) {
            console.error('Error changing password:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md px-8 py-6 mt-4 text-left bg-white rounded-lg shadow-lg">
                <h3 className="mb-8 text-2xl font-bold text-center text-gray-800">Change Password</h3>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-6">
                        <div>
                            <label className="block mb-2 text-gray-700" htmlFor="email">Email</label>
                            <input
                                type="email"
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-700" htmlFor="currentPassword">Current Password</label>
                            <input
                                type="password"
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-700" htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-700" htmlFor="confirmPassword">Confirm New Password</label>
                            <input
                                type="password"
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <button 
                                type="submit"
                                className="w-full py-3 text-white text-lg font-semibold bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-300"
                            >
                                Change Password
                            </button>
                        </div>
                    </div>
                </form>
                {error && <p className="mt-4 text-sm text-red-600">{error}</p>}
                {success && <p className="mt-4 text-sm text-green-600">{success}</p>}
            </div>
        </div>
    );
};

export default ChangePassword;
